<template>
  <div class="driver-info">
    <div class="head_nav">
      <van-nav-bar title="司机信息"
                   class="head_nar">
        <template #left>
          <van-icon @click="go(-1)"
                    name="arrow-left"
                    size="1.5rem"
                    color="#fff"
                    v-permission="{'Index':'Index'}" />
        </template>
        <template #right>
          <van-icon name="delete-o"
                    size="1.5rem"
                    color="#fff"
                    @click="cancellation"
                    v-permission="{'Index':'Index'}" />
        </template>
      </van-nav-bar>
    </div>
    <van-form class="mane">
      <div class="driverMsg">
        <van-field v-model="state.devoderimgiof.realName"
                   name="姓名"
                   label="姓名"
                   placeholder="请输入姓名"
                   readonly />

        <van-field v-model="state.devoderimgiof.idCardNo"
                   type="pasfz"
                   name="身份证"
                   label="身份证号"
                   placeholder="请输入身份证号"
                   readonly />
        <licplatenber @getPlateLicense="getPlateLicense" />
        <van-cell :title="Thebinding(state.devoderimgiof.bindStoreNo,state.devoderimgiof.bindStoreName)"
                  v-if="state.Accordingto">
          <template #right-icon>
            <van-button type="primary"
                        size="small"
                        @click="unbundling">解绑</van-button>
          </template>
        </van-cell>
        <van-cell title="车辆类型"
                  is-link
                  :value="state.devoderimgiof.carName" />
      </div>
      <div class="yiire">
        <van-row class="mangtect">
          <van-col span="12">身份证正面</van-col>
          <van-col span="12">身份证反面</van-col>
        </van-row>
        <van-row class="mangtect">
          <van-col span="12">
            <van-image width="100"
                       height="100"
                       @click="imger(state.devoderimgiof.idCardFrontImgUrl)"
                       :src="state.devoderimgiof.idCardFrontImgUrl" />
          </van-col>
          <van-col span="12">
            <van-image width="100"
                       height="100"
                       @click="imger(state.devoderimgiof.idCardBackImgUrl)"
                       :src="state.devoderimgiof.idCardBackImgUrl" />
          </van-col>
        </van-row>
      </div>
      <van-divider dashed
                   class="cut-offRule"></van-divider>
      <van-row class="mangtect">
        <van-col span="12">驾驶证</van-col>
        <van-col span="12">车辆照片</van-col>
      </van-row>
      <van-row class="mangtect">
        <van-col span="12">
          <van-image width="100"
                     height="100"
                     @click="imger(state.devoderimgiof.driverLicenseImgUrl)"
                     :src="state.devoderimgiof.driverLicenseImgUrl" />
        </van-col>
        <van-col span="12">
          <van-image width="100"
                     height="100"
                     @click="imger(state.devoderimgiof.carImgUrl)"
                     :src="state.devoderimgiof.carImgUrl" />
        </van-col>
      </van-row>

      <van-divider dashed
                   class="cut-offRule"></van-divider>
      <van-row class="mangtect">
        <van-col span="12">行驶证正面</van-col>
        <van-col span="12">行驶证反面</van-col>
      </van-row>
      <van-row class="mangtectes">
        <van-col span="12">
          <van-image width="100"
                     height="100"
                     @click="imger(state.devoderimgiof.drivingLicenseFrontImgUrl)"
                     :src="state.devoderimgiof.drivingLicenseFrontImgUrl" />
        </van-col>
        <van-col span="12">
          <van-image width="100"
                     height="100"
                     @click="imger(state.devoderimgiof.drivingLicenseCarImgUrl)"
                     :src="state.devoderimgiof.drivingLicenseCarImgUrl" />
        </van-col>

      </van-row>
      <van-divider dashed
                   class="cut-offRule"></van-divider>
    </van-form>
    <van-button type="primary"
                class="btnBottom"
                block
                @click="updainfon"
                v-permission="{'UpdateDriver':'UpdateDriver'}">修改信息</van-button>
  </div>
</template>
<script>
import { reactive, ref, onMounted, provide } from "vue";
import { ImagePreview } from 'vant';
import { useRouter } from "vue-router";
import { Toast } from "vant";
import { BackOff } from "@/commonContent/js/comm.js";
import { setCookie, getCookie } from '@/commonContent/js/Cookie'
import { IsNullOrEmpty, delUrlParam } from "@/commonContent/js/comm.js";
import { DriverIdentityInfoGetCurrUser, UpdateStoreUnbound, UpdateCancelDriver } from "@/serviceApi/Driver";
import licplatenber from "@/components/eup-OrderList/licplatenber.vue";
import { RefreshToken } from '@/serviceApi/Account'
import { Dialog } from 'vant';
import userty from "@/static/img/user/default-head.jpg"
export default {
  components: {
    licplatenber,
  },
  setup () {
    const router = useRouter();
    const state = reactive({
      devoderimgiof: {},
      Accordingto: false,
    });

    //响应式父子传值
    const plateNumber = ref("");
    const isSubmit = ref("show");
    provide("plateNumber", plateNumber);
    provide("isSubmit", isSubmit);

    const getPlateLicense = (val) => {
      state.devoderimgiof.licensePlate = val;
    };
    onMounted(() => {
      DriverIdentityInfoGetCurrUser().then((res) => {
        if (res.code == 1 && !IsNullOrEmpty(res.data)) {
          plateNumber.value = res.data.licensePlate
          if (res.data.idCardFrontImgUrl == "") {
            res.data.idCardFrontImgUrl = userty
          }
          if (res.data.drivingLicenseCarImgUrl == "") {
            res.data.drivingLicenseCarImgUrl = userty
          }
          if (res.data.drivingLicenseFrontImgUrl == "") {
            res.data.drivingLicenseFrontImgUrl = userty
          }
          if (res.data.carImgUrl == "") {
            res.data.carImgUrl = userty
          }
          if (res.data.driverLicenseImgUrl == "") {
            res.data.driverLicenseImgUrl = userty
          }
          if (res.data.idCardBackImgUrl == "") {
            res.data.idCardBackImgUrl = userty
          }
          state.devoderimgiof = res.data;
          if (state.devoderimgiof.bindStoreNo != "") {
            state.Accordingto = true
          }
        } else {
          var oldToken = getCookie("token");
          RefreshToken(oldToken).then((res) => {
            setCookie("token", res.data.token);
            DriverIdentityInfoGetCurrUser().then((res) => {
              if (res.code == 1 && !IsNullOrEmpty(res.data)) {
                plateNumber.value = res.data.licensePlate
                state.devoderimgiof = res.data;
                if (state.devoderimgiof.bindStoreNo != "") {
                  state.Accordingto = true
                }
              } else {
                Toast.fail(res.msg);
                return router.replace({ name: "Index" });
              }
            })
          })
        }
      });
    });
    const go = (val) => {
      BackOff(router.currentRoute.value)
      router.back(val);
    };
    const updainfon = () => {
      router.push({ name: "UpdateDriver" })
    };
    const Thebinding = (val, itme) => {
      return itme + ":" + val
    }
    const unbundling = () => {
      Dialog.confirm({
        title: '店铺解绑',
        message: '解绑商城店铺',
      })
        .then(() => {
          var pamser = {
            "BindStoreNo": state.devoderimgiof.bindStoreNo,
            "DriverId": state.devoderimgiof.driverId
          }
          UpdateStoreUnbound(pamser).then((res) => {
            if (res.code == 1) {
              Toast.success("解绑成功")
              state.Accordingto = false;
            } else {
              Toast.fail(res.msg)
            }
          });
        }).catch(() => {

        })
    }
    const cancellation = () => {
      Dialog.confirm({
        title: '司机注销',
        message: '注销易运之家司机',
      })
        .then(() => {
          UpdateCancelDriver().then((res) => {
            if (res.code == 1) {
              // 获取旧 token
              /* var oldToken = localStorage.getItem("token"); */
              var oldToken = getCookie("token");
              // 刷新token
              RefreshToken(oldToken).then(res => {
                if (res.code == 1) {
                  /* localStorage.setItem("token", res.data.token) */
                  setCookie("token", res.data.token)
                  Toast.success("注销成功")
                  router.replace({ path: "/User/Index" });
                }
                else {
                  window.location.href = "" + process.env.VUE_APP_API_UA + "/account/login?returnurl=" + encodeURIComponent(delUrlParam(window.location.href, ["logintoken"]));
                }
              });

            } else {
              Toast.fail(res.msg)
            }
          })
        })
        .catch(() => {
          // on cancel
        });
    }
    const imger = (val) => {
      ImagePreview({
        images: [val],
        showIndex: false,
      })
    }
    return {
      state,
      updainfon,
      router,
      go,
      imger,
      getPlateLicense, Thebinding, unbundling, cancellation
    };
  },
};
</script>
<style scoped>
</style>